/* eslint-disable linebreak-style */
/* eslint no-underscore-dangle: 0 */
// need a different package to store in cookies. "store" package stores quotes surrounding strings.
// TODO: use a single package
import Cookies from 'js-cookie';
import envConstants from './globals/constants/environment-constants';

const engine = require('store/src/store-engine');
const storages = [require('store/storages/localStorage'), require('store/storages/cookieStorage')];
const plugins = [require('store/plugins/expire')];

const store = engine.createStore(storages, plugins);

export function setBrowserStorage(key: string, value: string) {
  return store.set(key, value, new Date().getTime() + 30 * 24 * 60 * 60 * 1000);
}

// in some cases, need to set exclusively in Cookie, instead of the best available storage in browser
export function setCookieStorage(key: string, value: string) {
  return Cookies.set(key, value, { expires: 30 }); // expires in 30 days.
}

export function getBrowserStorage(key: string) {
  return store.get(key);
}

export function getCookieStorage(key: string) {
  return Cookies.get(key);
}

export function removeBrowserStorage(key: string) {
  return store.remove(key);
}

export function getUrlParam(name: string) {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
  const r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}

// eslint-disable-next-line import/prefer-default-export
export function addUrlParam(url: string, key: string, value: string): string {
  if (url !== null && url !== undefined) {
    const reg = new RegExp('([?&])' + key + '=.*?(&|$)', 'i'); // eslint-disable-line prefer-template
    const separator = url.indexOf('?') !== -1 ? '&' : '?';
    if (undefined !== value && value !== null) {
      if (url.match(reg)) {
        return url.replace(reg, `$1${key}=${value}$2`);
      }
      return `${url}${separator}${key}=${value}`;
    }
    if (url.match(reg)) {
      return url.replace(reg, '');
    }
  }
  return url;
}

export const redirect = {
  toEhmp: (args: {
    zipValue: string;
    countyValue: string;
    fastQuote?: boolean;
    productType?: string;
  }): void => {
    const urlParams = {
      zip: args.zipValue,
      county: args.countyValue,
      action: 'redirect_with_zip_code',
      lastV1Action: 'quoteFromV1',
      allid: '',
      gclid: '',
      mkt_code: '',
      msclkid: '',
      sid: '',
      pid: '',
      url: '',
      leadId: '',
      campaign: '',
      type: args.productType,
    };
    let redirectUrl = envConstants.EHMP_URL;
    if (getCookieStorage('ehi.alliance_sid')) {
      setBrowserStorage('sid', getCookieStorage('ehi.alliance_sid'));
    }
    if (getCookieStorage('ehi.alliance_pid')) {
      setBrowserStorage('pid', getCookieStorage('ehi.alliance_pid'));
    }
    if (getCookieStorage('ehi.alliance_campaign')) {
      setBrowserStorage('campaign', getCookieStorage('ehi.alliance_campaign'));
    }
    if (args.fastQuote) {
      redirectUrl += 'ehi/medicare/fast-quote';
      redirectUrl = addUrlParam(redirectUrl, 'requestFromFQ', 'true');
      redirectUrl = addUrlParam(redirectUrl, 'forceV1', 'true');
      redirectUrl = addUrlParam(redirectUrl, 'fastQuoteV2', 'Y');
    }

    Object.keys(urlParams).forEach((key) => {
      const value = urlParams[key] || getBrowserStorage(key);
      if (value) {
        redirectUrl = addUrlParam(redirectUrl, key, value);
      }
    });

    if (window._satellite) {
      const paramList = window._satellite.getVar('Cross Domain Parameters');
      if (paramList) {
        paramList.forEach((param) => {
          redirectUrl = addUrlParam(redirectUrl, param.key, param.value);
        });
      }
    }

    window.location.assign(redirectUrl);
  },
  toSmb: (args: { zipValue: string; employeeValue: string }): void => {
    const urlParams = {
      zipCode: args.zipValue,
      employeeEnrollingNumber: args.employeeValue,
      allid: '',
      gclid: '',
      mkt_code: '',
      msclkid: '',
      sid: '',
      pid: '',
      url: '',
      leadId: '',
      campaign: '',
    };
    let redirectUrl = `${envConstants.SITE_URL}/small-business-health-insurance/group-health-insurance-plans?fromPage=home`;
    Object.keys(urlParams).forEach((key) => {
      const value = urlParams[key] || getBrowserStorage(key);
      if (value) {
        redirectUrl = addUrlParam(redirectUrl, key, value);
      }
    });
    window.location.assign(redirectUrl);
  },
  toSmbLandingPage: (args: { zipValue: string; employerSize: 'small' | 'large' }): void => {
    const urlParams = {
      zipCode: args.zipValue,
      employeeEnrollingNumber: '',
      allid: '',
      gclid: '',
      mkt_code: '',
      msclkid: '',
      sid: '',
      pid: '',
      url: '',
      leadId: '',
      campaign: '',
    };
    let redirectUrl = `${envConstants.SITE_URL}/small-business-health-insurance/ichra-health-insurance?fromPage=home`;
    if (args.employerSize === 'small') {
      redirectUrl = `${envConstants.SITE_URL}/small-business-health-insurance?fromPage=home`;
    }
    Object.keys(urlParams).forEach((key) => {
      const value = urlParams[key] || getBrowserStorage(key);
      if (value) {
        redirectUrl = addUrlParam(redirectUrl, key, value);
      }
    });
    window.location.assign(redirectUrl);
  },
  toIfp: (args: { zipValue: string }): void => {
    const urlParams = {
      zipCode: args.zipValue,
      allid: '',
      gclid: '',
      mkt_code: '',
      msclkid: '',
      sid: '',
      pid: '',
      url: '',
      leadId: '',
      campaign: '',
    };
    let redirectUrl = `${envConstants.SITE_URL}/individual-family-health-insurance/plans`;
    Object.keys(urlParams).forEach((key) => {
      const value = urlParams[key] || getBrowserStorage(key);
      if (value) {
        redirectUrl = addUrlParam(redirectUrl, key, value);
      }
    });
    window.location.assign(redirectUrl);
  },
  toST: (args: { zipValue: string }): void => {
    const urlParams = {
      zipCode: args.zipValue,
      allid: '',
      gclid: '',
      mkt_code: '',
      msclkid: '',
      sid: '',
      pid: '',
      url: '',
      leadId: '',
      campaign: '',
    };
    let redirectUrl = `${envConstants.SITE_URL}/short-term-health-insurance/plans`;
    Object.keys(urlParams).forEach((key) => {
      const value = urlParams[key] || getBrowserStorage(key);
      if (value) {
        redirectUrl = addUrlParam(redirectUrl, key, value);
      }
    });
    window.location.assign(redirectUrl);
  },
  toDT: (args: { zipValue: string }): void => {
    const urlParams = {
      zipCode: args.zipValue,
      allid: '',
      gclid: '',
      mkt_code: '',
      msclkid: '',
      sid: '',
      pid: '',
      url: '',
      leadId: '',
      campaign: '',
    };
    let redirectUrl = `${envConstants.SITE_URL}/dental-insurance`;
    Object.keys(urlParams).forEach((key) => {
      const value = urlParams[key] || getBrowserStorage(key);
      if (value) {
        redirectUrl = addUrlParam(redirectUrl, key, value);
      }
    });
    window.location.assign(redirectUrl);
  },
  toVS: (args: { zipValue: string }): void => {
    const urlParams = {
      zipCode: args.zipValue,
      allid: '',
      gclid: '',
      mkt_code: '',
      msclkid: '',
      sid: '',
      pid: '',
      url: '',
      leadId: '',
      campaign: '',
    };
    let redirectUrl = `${envConstants.SITE_URL}/vision-insurance`;
    Object.keys(urlParams).forEach((key) => {
      const value = urlParams[key] || getBrowserStorage(key);
      if (value) {
        redirectUrl = addUrlParam(redirectUrl, key, value);
      }
    });
    window.location.assign(redirectUrl);
  },
  toAEI: (args: { zipValue: string }): void => {
    const urlParams = {
      zipCode: args.zipValue,
      allid: '',
      gclid: '',
      mkt_code: '',
      msclkid: '',
      sid: '',
      pid: '',
      url: '',
      leadId: '',
      campaign: '',
    };
    let redirectUrl = `${envConstants.SITE_URL}/about-ehealth-index`;
    Object.keys(urlParams).forEach((key) => {
      const value = urlParams[key] || getBrowserStorage(key);
      if (value) {
        redirectUrl = addUrlParam(redirectUrl, key, value);
      }
    });
    window.location.assign(redirectUrl);
  },
};

export function initInvoca(): void {
  if (typeof window === 'undefined') return;

  let WA_ENV = 'QA';
  if (location.host === 'www.ehealthinsurance.com') {
    WA_ENV = 'PRD';
  } else if (location.host === 'www.cm.ehealthinsurance.com') {
    WA_ENV = 'CM';
  }
  window._waStatic = { ...window._waStatic, invoca: { site: 'EHI', env: WA_ENV } };

  window.InvocaVars = window.InvocaVars || {};
  window.InvocaVars.plan_type = 'MC';
}

export function runInvoca(active?: number): void {
  if (typeof window === 'undefined') return;

  // keeping all planTypes as `MC` for now (except SMB) before ops team sets Invoca
  let planType: string;
  switch (active) {
    case 0:
      planType = 'MC';
      break;
    case 1:
      planType = 'IFP_IF';
      break;
    case 2:
      planType = 'SMB';
      break;
    case 3:
      planType = 'IFP_DV';
      break;
    case 4:
      planType = 'IFP_STM';
      break;
    default:
      planType = 'MC';
      break;
  }
  window.InvocaVars.plan_type = planType;

  window.Invoca?.PNAPI?.run();
}

export enum MedicarePlanType {
  MedicareAdvantage = 'Medicare Advantage',
  MedicareSupplement = 'Medicare Supplement',
}

export const handleKeyDown = (e: React.KeyboardEvent, callback: () => void) => {
  if (e.key === 'Enter' || e.key === ' ') {
    callback();
  }
};
